import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { environment } from '@environments/environment';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Observable, zip } from 'rxjs';
import { Link } from '../models/links.interface';

const API_ENDPOINT = environment.API_ENDPOINT;

@Injectable()
export class MenuService {

  constructor(
    private http: HttpClient,
    @Inject(SESSION_STORAGE) private storage: StorageService
  ) {}

  /**
   * Devuelve el menu del usuario logeado
   */
  getMenu(): Observable<any> {
    return this.http.get(`${API_ENDPOINT}v2/users/menu`);
  }

  /**
   * Get user name, photo and group
   */
  getUserData(): Observable<string[]> {
    const photo = this.storage.get('photo');
    const user = this.storage.get('user');
    const groupName = this.storage.get('groupName');
    return zip(user, photo, groupName);
  }

  welcome(): Observable<any> {
    return this.http.get(`${API_ENDPOINT}welcome`);
  }

  // Get clientify link
  getLinkClientify(){
    return this.http.get<Link[]>(`${API_ENDPOINT}linkclientify/${this.storage.get('userId')}`);
  }
  
  // Get Farmacia Asistencial link
  getLinkFarmaciaAsistencial(){
    return this.http.get<Link[]>(`${API_ENDPOINT}linkfarmaciaasistencial/${this.storage.get('userId')}`);
  }
}
