import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SocketService {
  count = 3;
  private updateMenuItemsSource = new BehaviorSubject<number>(0);
  public updateMenuItems$ = this.updateMenuItemsSource.asObservable();

  constructor() {
    // setInterval( () => {
    //   this.updateMenuItems();
    // }, 2000);
  }
  // private countdownSource = new BehaviorSubject<number>(0);
  // public countdown$ = this.countdownSource.asObservable();

  updateMenuItems() {
    console.log('update menu item');
    this.updateMenuItemsSource.next(this.count++);
  }
}
