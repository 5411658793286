import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { PortalModule } from '@angular/cdk/portal';
import { CodertyMaterialModule } from '../coderty-material/coderty-material.module';
import { SharedModule } from '../shared/shared.module';

import { CodertyLayoutComponent } from './coderty-layout.component';
import { CodertyLayoutToolbarComponent } from './components/toolbar/toolbar.component';
import { CodertyLayoutToolbarTitleComponent } from './components/coderty-layout-toolbar-title/coderty-layout-toolbar-title.component';
import { CodertyLayoutToolbarActionsComponent } from './components/coderty-layout-toolbar-actions/coderty-layout-toolbar-actions.component';
import { CodertyLayoutToolbarBackComponent } from './components/coderty-layout-toolbar-back/coderty-layout-toolbar-back.component';

import { CodertyLayoutToolbarTitleDirective } from './directives/toolbar-title.directive';
import { CodertyLayoutToolbarActionsDirective } from './directives/toolbar-actions.directive';
import { CodertyLayoutToolbarBackDirective } from './directives/toolbar-back.directive';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    CodertyMaterialModule,
    SharedModule,
    RouterModule,
    PortalModule
  ],
  exports: [
    CodertyLayoutComponent,
    CodertyLayoutToolbarComponent,
    CodertyLayoutToolbarTitleComponent,
    CodertyLayoutToolbarActionsComponent,
    CodertyLayoutToolbarBackComponent,
    CodertyLayoutToolbarTitleDirective,
    CodertyLayoutToolbarActionsDirective,
    CodertyLayoutToolbarBackDirective
  ],
  declarations: [
    CodertyLayoutComponent,
    CodertyLayoutToolbarComponent,
    CodertyLayoutToolbarTitleComponent,
    CodertyLayoutToolbarActionsComponent,
    CodertyLayoutToolbarBackComponent,
    CodertyLayoutToolbarTitleDirective,
    CodertyLayoutToolbarActionsDirective,
    CodertyLayoutToolbarBackDirective
  ],
  providers: [],
})
export class CodertyLayoutModule { }
