import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[codertyLayoutToolbarActions]'
})
export class CodertyLayoutToolbarActionsDirective {

  constructor(
    public template: TemplateRef<any>
  ) { }

}
