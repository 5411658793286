import { Component, AfterViewInit, OnDestroy, ViewChild, ComponentFactoryResolver, Injector, ApplicationRef } from '@angular/core';
import { PortalHost, CdkPortal, DomPortalHost } from '@angular/cdk/portal';

@Component({
  selector: 'coderty-layout-toolbar-back',
  templateUrl: './coderty-layout-toolbar-back.component.html',
  styleUrls: ['./coderty-layout-toolbar-back.component.sass']
})
export class CodertyLayoutToolbarBackComponent implements AfterViewInit, OnDestroy {

  private portalHost: PortalHost;
  @ViewChild(CdkPortal) portal;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef
  ) { }

  ngAfterViewInit(): void {
    // Create a portalHost from a DOM element
    this.portalHost = new DomPortalHost(
      document.querySelector('#coderty-layout-toolbar-back'),
      this.componentFactoryResolver,
      this.appRef,
      this.injector
    );

    // Attach portal to host
    this.portalHost.attach(this.portal);
  }

  ngOnDestroy(): void {
    this.portalHost.detach();
  }

}
