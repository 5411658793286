import { Routes } from '@angular/router';

export const LoginChildRoutes: Routes = [
  {
    path: 'login',
    loadChildren: 'app/coderty-core-front/login/login.module#LoginModule'
  }
];

export const CoreChildRoutes: Routes = [
  {
    path: 'users',
    loadChildren: 'app/coderty-core-front/users/users.module#UsersModule'
  },
  {
    path: 'groups',
    loadChildren: 'app/coderty-core-front/groups/groups.module#GroupsModule'
  },
  {
    path: 'historical',
    loadChildren:
      'app/coderty-core-front/historical/historical.module#HistoricalModule'
  },
  {
    path: 'charges',
    loadChildren:
      'app/coderty-core-front/charges/charge.module#ChargeModule'
  },
  {
    path: 'profiles',
    loadChildren:
      'app/coderty-core-front/profiles/profile.module#ProfileModule'
  },
  {
    path: 'managers',
    loadChildren:
      'app/coderty-core-front/managers/manager.module#ManagerModule'
  },
  {
    path: 'menus',
    loadChildren:
      'app/coderty-core-front/menus/menu.module#MenuModule'
  }
];
