import { NgModule } from '@angular/core';
import {
  MatToolbarModule,
  MatButtonModule,
  MatCheckboxModule,
  MatSidenavModule,
  MatDialogModule,
  MatSelectModule,
  MatInputModule,
  MatGridListModule,
  MatAutocompleteModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatIconModule,
  MatTableModule,
  MatProgressBarModule,
  MatCardModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatButtonToggleModule,
  MatMenuModule,
  MatSlideToggleModule,
  MatTabsModule,
  MatStepperModule,
  MatChipsModule,
  MatProgressSpinnerModule,
  MatListModule,
  MatRadioModule,
  MatExpansionModule,
  MatPaginatorModule
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  imports: [
    MatButtonModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatSidenavModule,
    MatDialogModule,
    MatSelectModule,
    MatInputModule,
    MatGridListModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatTableModule,
    MatProgressBarModule,
    MatCardModule,
    FlexLayoutModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatStepperModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatRadioModule,
    MatExpansionModule,
    MatPaginatorModule
  ],
  exports: [
    MatButtonModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatSidenavModule,
    MatDialogModule,
    MatSelectModule,
    MatInputModule,
    MatGridListModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatTableModule,
    MatProgressBarModule,
    MatCardModule,
    FlexLayoutModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatStepperModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatRadioModule,
    MatExpansionModule,
    MatPaginatorModule
  ]
})
export class CodertyMaterialModule {}
