import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';

import { Md5 } from 'ts-md5/dist/md5';

const LOGIN_API = 'login/';
const ACCESS_API = 'access/';

@Injectable()
export class LoginService {
  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(SESSION_STORAGE) private storage: StorageService
  ) { }

  /**
   * Login function
   * @param user {username, password} // TODO:: Add interface user
   */
  login(user): Observable<void> {
    return this.http
      .post(`${environment.API_ENDPOINT}${LOGIN_API}`, {
        username: user.username,
        password: Md5.hashStr(user.password)
      })
      .pipe(
        catchError(err => {
          return throwError(
            'Error al introducir el usuario o la contraseña'
          );
        }),
        map((res: any) => {
          if (res && res.roles) {
            this.setUserInLocalStorage(
              res.roles,
              res.groupName,
              res.photo,
              res.user,
              res.groupId
            );
            res.loginState
              ? this.router.navigate([res.loginState])
              : this.router.navigate([environment.initial_state]);
          }
        })
      );
  }

  /**
   * Logout user and romeve data from localstorage
   */
  logout() {
    this.http
      .get(`${environment.API_ENDPOINT}${ACCESS_API}logout`)
      .subscribe(() => {
        this.clearStorage();
      });
  }

  clearStorage(): void {
    this.storage.clear();
    environment.logout_url
      ? (window.location.href = environment.logout_url)
      : this.router.navigate(['/']);
  }

  /**
   * Check if user is loggedin
   */
  checkLoggedIn(): Observable<boolean> {
    return this.http
      .get(`${environment.API_ENDPOINT}${ACCESS_API}loggedin`)
      .pipe(
        map((res: any) => {
          if (res) {
            this.storage.set('userId', res.id!);
            return true;
          } else {
            environment.logout_url
              ? (window.location.href = environment.logout_url)
              : this.router.navigate(['/']);
            return false;
          }
        })
      );
  }

  setUserInLocalStorage(roles, groupName, photo, user, groupId) {
    this.storage.remove('userRoles');
    this.storage.set('userRoles', roles);
    this.storage.remove('groupName');
    this.storage.set('groupName', groupName);
    this.storage.remove('groupId');
    this.storage.set('groupId', groupId);
    this.storage.remove('photo');
    this.storage.set('photo', photo);
    this.storage.remove('user');
    this.storage.set('user', user);
  }
}
