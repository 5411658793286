import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';


// Custom
import { LoginService } from '../../login/login.service';
import { Router } from '@angular/router';

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
  private _route;

  constructor(
    public route: Router,
    private loginService: LoginService
  ) {
    this._route = route;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    return (
      next
        .handle(request)
        .pipe(
          catchError( (error: HttpErrorResponse) =>  {
            this.manageError(error);
            return throwError(error);
          })
        )
        /* .do((event: HttpEvent<any>) => {
        console.log(event);
        if (event instanceof HttpResponse) {
          // do stuff with response if you want
        }
      }, (err: any) => {
        console.log(err);
        if (err instanceof HttpErrorResponse) {
          this.manageError(err);
        }
      }) */

    );
  }

  manageError(error) {
    if (error.status === 403 || error.status === 502) {
      this.loginService.logout();
    } else if (error.status === 401 && this._route.url !== '/login') {
      this.loginService.clearStorage();
    }
  }
}
