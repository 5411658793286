import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[codertyLayoutToolbarBack]'
})
export class CodertyLayoutToolbarBackDirective {

  constructor(
    public template: TemplateRef<any>
  ) { }

}
