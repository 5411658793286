import { Component, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { pluck } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationError
} from '@angular/router';
import { MatSnackBar, MatSidenav } from '@angular/material';
import { CodertyLayoutService } from './coderty-layout.service';

@Component({
  selector: 'coderty-layout',
  templateUrl: './coderty-layout.component.html',
  animations: [
    trigger('growInOut', [
      state('in', style({ opacity: 1 })),
      transition('void => *', [
        style({
          opacity: 0,
          transform: 'scale3d(.3, .3, .3)'
        }),
        animate(`150ms ease-in`)
      ]),
      transition('* => void', [
        animate(
          `150ms ease-out`,
          style({
            opacity: 0,
            transform: 'scale3d(.3, .3, .3)'
          })
        )
      ])
    ])
  ],
  styleUrls: ['./coderty-layout.component.sass']
})
export class CodertyLayoutComponent implements OnInit {
  public isSmallScreen: boolean;
  private isLoadingSubject = new ReplaySubject<boolean>(1);
  public isLoadingView$ = this.isLoadingSubject.asObservable();

  @ViewChild('sidenavLayout') sidenavLayout: MatSidenav;

  constructor(
    private _breakpointObserver: BreakpointObserver,
    private router: Router,
    private snackBar: MatSnackBar,
    private layoutService: CodertyLayoutService
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.isLoadingSubject.next(true);
      } else if (event instanceof NavigationEnd) {
        this.isLoadingSubject.next(false);
        // Comprobamos si es un formulario
        // if (event.url.indexOf('form') !== -1) {
        //   this.myNav.close();
        //   this.isFormSubject.next(true);
        // } else {
        //   this.myNav.open();
        //   this.isFormSubject.next(false);
        // }
        // if (this.matSidenavMode === 'over') {
        //   this.myNav.close();
        // }
      } else if (event instanceof NavigationError) {
        this.isLoadingSubject.next(false);
        this.snackBar.open('Error al cargar la página', 'Aceptar', {
          duration: 2000
        });
      }
    });

    this.layoutService.toggleMenuPushed$.subscribe(() => {
      this.sidenavLayout.toggle();
    });
  }

  ngOnInit() {
    this._breakpointObserver
      .observe(['(max-width: 1366px)'])
      .pipe(pluck('matches'))
      .subscribe((m: boolean) => (this.isSmallScreen = m));
  }

  get sidenavMode() {
    return this.isSmallScreen ? 'over' : 'side';
  }

  selectMenu() {
    if (this.isSmallScreen) {
      this.sidenavLayout.toggle();
    }
  }
}
