// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  ENDPOINT: 'http://localhost:4200/',
  API_ENDPOINT: 'http://localhost:4200/api/',
  initial_state: '/dashboard',
  connectSocket: true,
  logout_url: 'https://www.farmacias.com/areacliente/',
  logout_text: 'Volver al área de cliente',
  avatarImage: 'assets/images/avatar.svg',
  MENU_FARMACIA_ASISTENCIAL_ALL_USERS: false, // If you change to true this value, you have to modify the link, class icon and name
  MENU_FARMACIA_ASISTENCIAL_LINK: '',
  MENU_FARMACIA_ASISTENCIAL_CLASS_ICON: '',
  MENU_FARMACIA_ASISTENCIAL_NAME: '',
  MENU_CLIENTIFY_ALL_USERS: false, // If you change to true this value, you have to modify the link, class icon and name
  MENU_CLIENTIFY_LINK: '',
  MENU_CLIENTIFY_CLASS_ICON: '',
  MENU_CLIENTIFY_NAME: '',
};
