import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CodertyLayoutService {

  constructor() { }

  private toggleMenuPushedSource = new Subject<any>();

  toggleMenuPushed$ = this.toggleMenuPushedSource.asObservable();

  toggleMenu() {
    this.toggleMenuPushedSource.next();
  }
}
