import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import esEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
// Modules
import { SharedModule } from './coderty-core-front/shared/shared.module';
import { AuthGuard } from './coderty-core-front/shared/auth-guard.service';
import { HttpInterceptorProviders } from './coderty-core-front/shared/http-interceptors';
import { StorageServiceModule } from 'ngx-webstorage-service';
import { MatPaginatorIntl } from '@angular/material';
import { CodertyIntlES } from './coderty-core-front/shared/utils/table-utils/coderty-intl-es';

// Components
import { AppComponent } from './app.component';
import { CodertyLayoutModule } from './coderty-core-front/coderty-layout/coderty-layout.module';
import { CustomReuseStrategy } from './coderty-core-front/shared/custom-reuse-strategy.service';
// Routes
import { AppRoutes } from './app.routing';

registerLocaleData(esEs);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, {useHash: true}),
    HttpClientModule,
    SharedModule,
    CodertyLayoutModule,
    StorageServiceModule
  ],
  providers: [
    AuthGuard,
    HttpInterceptorProviders,
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    { provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: MatPaginatorIntl, useClass: CodertyIntlES }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
