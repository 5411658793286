import { Routes } from '@angular/router';
import { CodertyLayoutComponent } from './coderty-core-front/coderty-layout/coderty-layout.component';
// Custom
import { LoginChildRoutes, CoreChildRoutes } from './coderty-core-front/core.routing';
import { AuthGuard } from './coderty-core-front/shared/auth-guard.service';
import { FarmaciasRoutes, FarmaciasLoginRoutes } from 'app/farmacias/farmacias.routing';

export const AppRoutes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: '',
    children: [
      ...LoginChildRoutes,
      ...FarmaciasLoginRoutes
    ]
  },
  {
    path: '',
    component: CodertyLayoutComponent,
    canActivate: [
      AuthGuard,
    ],
    children: [
      ...CoreChildRoutes,
      ...FarmaciasRoutes
    ]
  }

];
