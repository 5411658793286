import { Component, OnInit, ContentChild, TemplateRef } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { pluck } from 'rxjs/operators';
import { CodertyLayoutToolbarTitleDirective } from '../../directives/toolbar-title.directive';
import { CodertyLayoutToolbarActionsDirective } from '../../directives/toolbar-actions.directive';
import { CodertyLayoutToolbarBackDirective } from '../../directives/toolbar-back.directive';
import { CodertyLayoutService } from '../../coderty-layout.service';

@Component({
  selector: 'coderty-layout-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.sass']
})
export class CodertyLayoutToolbarComponent implements OnInit {

  @ContentChild(CodertyLayoutToolbarTitleDirective, { read: TemplateRef })
  toolbarTitleTemplate: TemplateRef<any>;
  @ContentChild(CodertyLayoutToolbarActionsDirective, { read: TemplateRef })
  toolbarActionsTemplate: TemplateRef<any>;
  @ContentChild(CodertyLayoutToolbarBackDirective, { read: TemplateRef })
  toolbarBackTemplate: TemplateRef<any>;

  isSmallScreen: boolean;

  constructor(
    private layoutService: CodertyLayoutService,
    private _breakpointObserver: BreakpointObserver
  ) { }

  ngOnInit() {
    this._breakpointObserver
      .observe(['(max-width: 1366px)'])
      .pipe(pluck('matches'))
      .subscribe((m: boolean) => (this.isSmallScreen = m));
  }

  toggleSidenavLayout() {
    this.layoutService.toggleMenu();
  }

}
