import { Router } from '@angular/router';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  Inject
} from '@angular/core';

import * as io from 'socket.io-client';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';

// Custom
import { LoginService } from './../../login/login.service';
import { MenuService } from './menu.service';
import { Header } from './../models/header.interface';
import { Menu } from '../models/menu.interface';
import { SocketService } from './../socket.service';
import { environment } from '@environments/environment';
import { Link } from '../models/links.interface';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  providers: [SocketService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent implements OnInit {
  menus: Header[] = [];
  links: Header[];
  username: string;
  photo: string;
  groupname: string;
  count = 0;
  logoutText = 'Cerrar sesión';
  avatarImage = 'assets/images/avatar.jpg';

  private isAnyMenuOpened = false;
  private socket: SocketIOClient.Socket; // The client instance of socket.io
  @Output() onSelectMenu = new EventEmitter<any>();

  constructor(
    private menuService: MenuService,
    private router: Router,
    private loginService: LoginService,
    private socketService: SocketService,
    private cd: ChangeDetectorRef,
    @Inject(SESSION_STORAGE) private storage: StorageService
  ) {
    if (environment.connectSocket) {
      this.socket = io(`${environment.ENDPOINT}`);
    }
  }

  ngOnInit() {
    if (environment.connectSocket) {
      const _self = this;
      this.socket.on('notificationDelay', function (data: any) {
        _self.updateMenuNotif(data);
      });
      // this.callWelcome();
    }

    if (environment.logout_text) {
      this.logoutText = environment.logout_text;
    }

    if (environment.avatarImage) {
      this.avatarImage = environment.avatarImage;
    }

    this.menuService.getUserData().subscribe(res => {
      this.username = res[0];
      this.photo = res[1] ? 'data:image/png;base64,' + res[1] : null;
      this.groupname = res[2];
      this.cd.markForCheck();
    });

    this.menuService.getMenu().subscribe(res => {
      res.forEach(link => {
        this.menus.push(link)
      });
      this.cd.markForCheck();
    });

    if (Number(this.storage.get('groupId')) != 3) {

      if (environment.MENU_FARMACIA_ASISTENCIAL_ALL_USERS) {
        this.menus.push(this.prepareLinks({
          id: 0,
          name: environment.MENU_FARMACIA_ASISTENCIAL_NAME,
          ref: environment.MENU_FARMACIA_ASISTENCIAL_LINK,
          classIcon: environment.MENU_FARMACIA_ASISTENCIAL_CLASS_ICON
        }))
        this.cd.markForCheck();
      } else {
        this.menuService.getLinkFarmaciaAsistencial().subscribe(res => {
          res.forEach(link => {
            this.menus.push(this.prepareLinks(link))
          });
          this.cd.markForCheck();
        })
      }

      if (environment.MENU_CLIENTIFY_ALL_USERS) {
        this.menus.push(this.prepareLinks({
          id: 0,
          name: environment.MENU_CLIENTIFY_NAME,
          ref: environment.MENU_CLIENTIFY_LINK,
          classIcon: environment.MENU_CLIENTIFY_CLASS_ICON
        }))
        this.cd.markForCheck();
      } else {
        this.menuService.getLinkClientify().subscribe(res => {
          res.forEach(link => {
            this.menus.push(this.prepareLinks(link))
          });
          this.cd.markForCheck();
        })

      }
    }

    this.socketService.updateMenuItems$.subscribe(res => {
      // console.log('res en subscribe', res);
      this.count = res;
      this.cd.markForCheck();
    });

    // setTimeout( () => {
    //   console.log('timer');
    //   this.socketService.updateMenuItems( );
    // }, 1000);
    // this.socket.on('notificationDelay', function( res ){
    //   console.log('Socket notification:', res);
    // });
  }

  prepareLinks(link: Link): Header {
    return {
      id: link.id,
      title: 'HEADER',
      class: 'hidden-folded padder m-t m-b-sm text-muted text-xs',
      name: 'Navigation',
      menus: [{
        class: link.classIcon,
        name: link.name,
        sref: link.ref,
        title: link.name,
        id: link.id,
        state: 'active'
      }]
    }
  }

  toggleMenu(menu: Menu): void {
    if (menu.state === 'active') {
      this.isAnyMenuOpened = false;
      menu.state = 'inactive';
    } else {
      this.isAnyMenuOpened = true;
      menu.state = 'active';
    }
  }

  /**
   * Elemento del menu raíz seleccionado
   * @param sref
   */
  onMenuSelected(menu): void {
    // Comprobamos si hay un menu desplegado y lo cerramos
    if (this.isAnyMenuOpened) {
      const openedMenu = this.checkActiveMenu(this.menus);
      if (openedMenu) {
        this.toggleMenu(openedMenu);
      }
    }
    // menu.state = 'active';
    // TODO:: Desactivar el resto de activos
    this.onSelectMenu.emit(true);
    this.router.navigate([menu.sref]).catch(err => window.open(menu.sref))
  }

  /**
   * Estado de submenu seleccionado
   * @param sref
   */
  onSubMenuSelected(menu): void {
    // menu.state = 'active';
    // TODO:: Desactivar el resto de activos
    this.onSelectMenu.emit(true);
    this.router.navigate([menu.sref]);
  }

  logout(): void {
    this.onSelectMenu.emit(true);
    this.loginService.logout();
  }

  /**
   * Devuelve el menu activo (desplegado)
   * @param menus
   * @return the active menu
   */
  checkActiveMenu(menus: Header[]): Menu {
    const _totalHeaders = menus.length;
    for (let i = 0; i < _totalHeaders; i++) {
      const header = menus[i];
      const _totalMenus = header.menus.length;
      for (let j = 0; j < _totalMenus; j++) {
        const _menu = header.menus[j];
        if (_menu.state === 'active') {
          return _menu;
        }
      }
    }
    return null;
  }

  updateMenuNotif(data: any) {
    let notified = false;
    if (this.menus && this.menus.length) {
      for (let i = 0; i < this.menus.length; i++) {
        if (this.menus[i].menus && this.menus[i].menus.length) {
          for (let j = 0; j < this.menus[i].menus.length; j++) {
            if (this.menus[i].menus[j].id === data.menuId) {
              this.menus[i].menus[j].notif = data.key;
              j = this.menus[i].menus.length;
              notified = true;
              this.cd.markForCheck();
            }
          }
          if (notified) {
            i = this.menus.length;
          }
        }
      }
    }
  }

  callWelcome() {
    this.menuService.welcome().subscribe(res => {
    });
  }
}
