import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[codertyLayoutToolbarTitle]'
})
export class CodertyLayoutToolbarTitleDirective {

  constructor(
    public template: TemplateRef<any>
  ) { }

}
