import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
// Custom
import { CodertyMaterialModule } from '../coderty-material/coderty-material.module';
import { MenuComponent } from './menu/menu.component';
import { MenuService } from './menu/menu.service';
import { CodertyFilterPipe } from './pipes/coderty-filter/coderty-filter.pipe';
import { AbsolutePipe } from './pipes/absolute/absolute.pipe';
import { LoginService } from '../login/login.service';
import { Md5 } from 'ts-md5';
import { HttpInterceptorProviders } from './http-interceptors';

@NgModule({
  imports: [
    CommonModule,
    CodertyMaterialModule,
    RouterModule
  ],
  declarations: [
    MenuComponent,
    CodertyFilterPipe,
    AbsolutePipe
  ],
  providers: [
    MenuService,
    LoginService,
    Md5,
    HttpInterceptorProviders
  ],
  exports: [
    MenuComponent,
    CodertyMaterialModule,
    CodertyFilterPipe,
    AbsolutePipe
  ]
})
export class SharedModule {}
