import { Routes } from '@angular/router';

export const FarmaciasRoutes: Routes = [
  {
    path: 'orders',
    loadChildren: 'app/farmacias/orders/orders.module#OrdersModule'
  },
  {
    path: 'billing',
    loadChildren: 'app/farmacias/billing/billing.module#BillingModule'
  },
  {
    path: 'products',
    loadChildren: 'app/farmacias/products/products.module#ProductsModule'
  },
  {
    path: 'search',
    loadChildren: 'app/farmacias/products-search/products-search.module#ProductsSearchModule'
  },
  {
    path: 'dashboard', 
    loadChildren: 'app/farmacias/dashboard/dashboard.module#DashboardModule'
  },
  {
    path: 'customers',
    loadChildren: 'app/farmacias/customers/customers.module#CustomersModule'
  },
  {
    path : 'chats',
    loadChildren: 'app/farmacias/chats/chats.module#ChatsModule'
  }
];

export const FarmaciasLoginRoutes: Routes = [
  {
    path: 'login',
    loadChildren: 'app/farmacias/farmacias-login/farmacias-login.module#FarmaciasLoginModule'
  }
];